.centering {
  max-width: 700px;
  margin: 0 auto;
  padding-left: 15px;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  color: #eeeeee;
}

.textcenter {
  text-align: center;
}

.txtleft {
  text-align: left;
}

.txtright {
  text-align: right;
}

.abstand-15 {
  height: 15px;
}

.abstand-30 {
  height: 30px;
}

.abstand-400 {
  height: 400px;
}

.ueberschrift {
  font-family: "Consolas", Georgia, Times, sans-serif;
  font-size: 1.6em;
  font-weight: 800;
}

.summary {
  font-family: "Tahoma", Arial, Times, sans-serif;
  font-size: 0.8em;
  font-weight: 100;
}

.art-text {
  font-family: "Tahoma", Arial, Times, sans-serif;
  font-size: 0.9em;
}

.number-comments {
  font-family: Arial, Times, sans-serif;
  font-size: 0.75em;
  font-weight: 600;
}

.comment-handle {
  font-family: Arial, Times, sans-serif;
  font-size: 0.55em;
  font-weight: 750;
}

.comment-display {
  font-family: Arial, Times, sans-serif;
  font-size: 0.75em;
  font-weight: 300;
}

.language-display {
  font-family: Arial, Times, sans-serif;
  font-size: 0.55em;
  font-weight: 300;
}

.weiter-zruueck {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.impressum-ausrichtung {
  font-size: 0.6em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.app-bar {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.pointer {
  cursor: pointer;
}

.img-width {
  width: 20px;
}

.img-article {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.img-uebersicht {
  display: block;
  max-width: 100%;
  max-height: 100px;
}

.consent-div {
  background-color: gray;
  height: 150px;
  max-width: 350px;
  padding: 15px;
}

.tweet-breite {
  max-width: 350px;
  margin: 0 auto;
}
